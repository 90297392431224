.share {
    background-color: var(--success);
    width: 300px;
    position: fixed;
    bottom: 3vw;
    left: 3vw;
    overflow: auto;
    z-index: 1000;
    padding: 0;
    margin: 0;

    box-shadow: 5px 5px 10px #333;
}

.share .card-footer {
    display: flex;
    justify-content: space-between;
}
.share a {
    color: inherit;
}

.share .hidden {
    display: none;
}

.share-btn {
    display: inline-block;
    opacity: 0.8;
    padding: 0 0.2rem 0.2rem 0.2rem;
    color: #ffffff !important;
    width: 2.5rem;
    border-radius: 2px;
    overflow: hidden;
    text-align: center;
    line-height: 1.5rem;
}
.share-btn .hidden {
    display: none;
    margin-left: 0.5rem;
}

.share-btn:hover {
    opacity: 1;
    color: #000000 !important;
}

.share-btn.twitter {
    background: #55acee;
}
.share-btn.facebook {
    background: #3b5998;
}
.share-btn.stumbleupon {
    background: #eb4823;
}
.share-btn.reddit {
    background: #ff5700;
}
.share-btn.linkedin {
    background: #4875b4;
}
.share-btn.email {
    background: #444444;
}
