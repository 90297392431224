.banner {
    margin: 0;
    border: 0;
    border-radius: 0;
    padding: 4px;
}

/* Hide the extra info on small screens */
.bannerText,
.linkText {
    display: none;
}

/* Display it on a larger one */
@media only screen and (min-width: 768px) {
    .banner {
        padding: 12px;
    }
    .bannerText,
    .linkText {
        display: inline;
    }
}
