.Dashboard {
    max-width: 1024px;
    margin: auto;
}

.Dashboard .graph {
    cursor: pointer;
}

/* .Dashboard .graph:hover {
    box-shadow: 5px 5px 10px #333;
} */
