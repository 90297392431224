/* In the year calendar, we will have a dot for each */
.graphMeta .line {
    overflow: hidden;
    background-color: black;
    width: 20px;
    height: 4px;
    border-radius: 1px;
}

.graphMeta .line.target {
    background-color: blue;
}

.graphMeta {
    font-size: 0.75rem;
    margin: 0.25rem 0 0.5rem 0;
    text-align: left;
    float: left;
    letter-spacing: 0px;
}

.graphMeta .caption,
.graphMeta .key,
.graphMeta .line {
    display: inline-block;
}
.graphMeta .caption,
.graphMeta .key {
    opacity: 0.8;
}
.graphMeta .line {
    margin-left: 0.5rem;
    margin-right: 0.25rem;
}
