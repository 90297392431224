/* Centre it */
.calendar {
    text-align: center;
}

/* Override my centering from above */
.calendar .card-header {
    text-align: left;
}

/* Override Bootstrap padding */
.calendar .card-body {
    padding: 0;
}

/* Make it as narrow as it can be */
.calwrap {
    padding-top: 1rem;
    display: inline-block;
}

/* Various colours for the different types of status */
.notdone {
    background: lightgrey !important;
}

.notyet {
    background: grey !important;
}

.today {
    background: orange !important;
}

.fail {
    background: red !important;
}

.pass {
    background: green !important;
}

/* Inspired by the front page of GitHub */
#yearCal {
    grid-template-rows: repeat(7, 4px);
    display: grid;
    grid-auto-flow: column;
    row-gap: 1px;
    column-gap: 1px;
}

/* In the year calendar, we will have a dot for each */
.square {
    overflow: hidden;
    background-color: green;
    width: 4px;
    height: 4px;
    border-radius: 1px;
}

.calendarMeta {
    font-size: 0.75rem;
    margin: 0.25rem 0 0.5rem 0;
    text-align: left;
    letter-spacing: 0px;
}

.calendarMeta .caption,
.calendarMeta .key,
.calendarMeta .square {
    display: inline-block;
}
.calendarMeta .caption,
.calendarMeta .key {
    opacity: 0.8;
}
.calendarMeta .square {
    margin-left: 0.5rem;
    margin-right: 0.25rem;
}
@media only screen and (min-width: 768px) {
    #yearCal {
        grid-template-rows: repeat(7, 10px);
        row-gap: 2px;
        column-gap: 2px;
    }
    .square {
        width: 10px;
        height: 10px;
        border-radius: 2px;
    }
}
@media only screen and (min-width: 1024px) {
    #yearCal {
        grid-template-rows: repeat(7, 14px);
        row-gap: 2px;
        column-gap: 2px;
    }
    .square {
        width: 14px;
        height: 14px;
        border-radius: 3px;
    }
}
