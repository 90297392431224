.profile form img,
.profile form svg {
    padding: 10px;
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

.profile form .profileImage {
    text-align: center;
    height: 25vh;
}

.createdOn {
    padding-left: 10px;
}

.profile form textarea {
    height: 100px;
}

.profile .form-label {
    font-size: 0.8rem;
}

.toast {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (min-width: 720px) {
    .profile form .profileImage {
        text-align: left;
        height: 320px;
    }
}
