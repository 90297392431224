.carousel {
    display: none;
}

img {
    margin: auto;
}

.carousel-caption {
    background: rgba(50, 50, 50, 0.5);
    padding: 1rem;
}

@media only screen and (min-width: 768px) {
    .carousel {
        display: block;
    }
    .fp-carousel {
        display: none;
    }
}
