.monthCalendar .card-header {
    display: flex;
    justify-content: space-between;
    user-select: none;
}

p.dayname {
    font-size: 0;
    /* font-size: 0.75rem; */
}
p.dayname::first-letter {
    font-size: 1rem;
}

.monthCalendar .card-body {
    margin: 0 1rem;
}

#dayofweek,
#daygrid {
    display: grid;
    text-align: center;
    justify-content: stretch;
    grid-template-columns: repeat(7, 1fr);

    grid-gap: 1px;
    border: none;
    background: none;
}

.monthCal.calendarMeta {
    display: flex;
    flex-wrap: wrap;
}

span.dayname,
span.date {
    display: none;
}

.weekend {
    font-weight: bold;
}
p.fullday {
    display: none;
}

p.daynum {
    display: inline;
    box-sizing: border-box;

    font-size: 0.75rem;
    line-height: 1.5rem;
    margin-left: 0;
    padding: 0.5rem;
}

.daynumsquare {
    cursor: pointer;
    border-radius: 2px;
}

.monthName {
    cursor: pointer;
}

div.wordcount {
    display: none;
}
