.navbar {
    background: rgba(251, 251, 248, 0.8);
    width: 100%;
}

.navbar-brand {
    font-family: 'Dancing Script', cursive !important;
}

.navbar-brand {
    font-size: 2.5rem;
}

.nav-link {
    font-size: 1.2rem;
}

.container {
    box-sizing: border-box;
}

.loading .card-title,
.loading .card-text,
.loading .card-footer,
.loading .square {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        background-color: #eee;
        color: #eee;
    }
    50% {
        background-color: #ddd;
        color: #ddd;
    }
    100% {
        background-color: #eee;
        color: #eee;
    }
}
