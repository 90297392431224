/* Fonts */

@font-face {
    font-family: 'Charter';
    src: url('./fonts/charter_italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Charter';
    src: url('./fonts/charter_bold_italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Charter';
    src: url('./fonts/charter_bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Charter';
    src: url('./fonts/charter_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

html {
    background-color: black;
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Charter', serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url('./images/background/orange-top-512w.jpg') no-repeat fixed;
    background-position: top 32px left 0px;
    background-size: contain;
}
@media all and (min-width: 468px) {
    body {
        background-size: cover;
    }
}

@media all and (min-width: 513px) {
    body {
        background: url('./images/background/orange-top-1024w.jpg') no-repeat
            fixed;
    }
}
@media all and (min-width: 1025px) {
    body {
        background: url('./images/background/orange-top-2048w.jpg') no-repeat
            fixed;
    }
}
@media all and (min-width: 2049px) {
    body {
        background: url('./images/background/orange-top-3240w.jpg') no-repeat
            fixed;
    }
}
@media all and (min-width: 3241px) {
    body {
        background: url('./images/background/orange-top-5616w.jpg') no-repeat
            fixed;
    }
}

.container {
    max-width: 1024px !important;
    background-color: rgb(251, 251, 248);
    border-radius: 4px;
    box-sizing: content-box;
    /* padding: 10px; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Dancing Script', cursive !important;
}

/* Prevent form inputs being zoomed in on */
select.form-control,
textarea.form-control,
input.form-control {
    font-size: 1rem;
}
input[type='file'] {
    width: 100%;
}
