.stats .card {
    text-align: center;
}

.stats .card-text {
    line-height: 1rem;
}

.stats .card-title {
    font-family: inherit !important;
}
