.float {
    position: fixed;
    bottom: 3vw;
    right: 3vw;
    overflow: auto;
    z-index: 1000;
}

.spin {
    animation: spinner 10s infinite linear;
}

/* Animation takes 80% of the time, 20% is left as 'default icon' */
@keyframes spinner {
    /* Start front facing */
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
    /* Pause for 20% of the time - then each 20% here is 1/4 turn */
    80% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
    85% {
        transform: rotate3d(0, 1, 0, 90deg);
    }
    90% {
        transform: rotate3d(0, 1, 0, 180deg);
    }
    95% {
        transform: rotate3d(0, 1, 0, 270deg);
    }
    100% {
        transform: rotate3d(0, 1, 0, 360deg);
    }
}
